import React from 'react'
import './Styles/HeaderStyles.css'
import { AiOutlineSearch } from 'react-icons/ai'
import { GiShoppingCart } from 'react-icons/gi'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import Search from './Search'
import { useSelector } from 'react-redux'
import { BasketSelectors } from '../Sections/Basket'

export default function Header() {
    const navigate = useNavigate()
    const basketProducts = useSelector(BasketSelectors.basketProducts);

    return (
        <div className="headerContainer">
            <div className='logoContainer'>
                <img className='imgContainer' src={require('../Assets/Images/logo1.png')} />
            </div>
            <div className='searchContainer'>
                {/* <Search /> */}
            </div>

            <div className='navRightContainer'>
                <div className='headerButtonContainer'>
                    <Button
                        number={basketProducts.length}
                        onClick={() => navigate(ScreenNames.BASKET_SCREEN)}
                        className="mainButtonIconContainer"
                        iconStyle={{ width: 30, height: 30, marginRight: 5 }}
                        reverse
                        icon={(style) => <GiShoppingCart style={style} />}
                        text={"Sepetim"} />
                </div>
            </div>
        </div>
    )
}
