import { collection, getDocs, limit, query, startAfter, where } from "firebase/firestore";
import { db } from "../../../firebase";

export const getProductsByCampaignId = async (args) => {
    try {

        const campaignId = args?.campaignId;
        const productsRef = collection(db, 'products');
        const productQuery = !args?.lastDocument ? query(productsRef, where('campaignId', '==', campaignId), limit(12)) : query(productsRef, where('campaignId', '==', campaignId), limit(12), startAfter(args?.lastDocument));
        const querySnapshot = await getDocs(productQuery);

        const productList = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return data;
        });

        const lastData = querySnapshot.docs[querySnapshot.docs.length - 1];

        return {
            list: productList,
            lastDocument: lastData,
        };
    }
    catch (error) {
        return null;
    }
};
