import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import { useSelector } from 'react-redux';
import { BasketSelectors } from '..';

export default function BasketLoader() {
    const loading = useSelector(BasketSelectors.loading);

    return !loading ? null : (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0.9)',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <TailSpin
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <span style={{
                fontSize: 18,
                marginTop: 20
            }}>Siparişiniz oluşturuluyor...</span>
        </div>
    )
}
