import React from 'react'
import { GiShoppingCart } from 'react-icons/gi'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import './Styles/ProductItem.css'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import { sendMessage } from '../Utils/Whatsapp'
import { useDispatch } from 'react-redux'
import { BasketActions } from '../Sections/Basket'
import BlankProductComponent from '../Sections/Products/Components/BlankProductComponent'

export default function ProductItem({
    product,
    title,
    price,
    id,
    image
}) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const _onClick = () => {
        navigate(ScreenNames.PRODUCT_SCREEN + `${id}`,
            {
                state: {
                    data: product
                }
            })
    };

    const _addToBasket = () => {
        dispatch(BasketActions.fetchBasketProducts({
            product: product,
            type: 'ADD'
        }))
    }

    const splitedPrice = price ? price.split('.') : [price, '00'];
    // return (
    //     <BlankProductComponent />

    // )

    const _renderContent = () => {
        if (id) {
            return (
                <>
                    <div onClick={() => _onClick()}
                        style={{

                            width: 228,
                            height: 340,
                            position: 'absolute'
                        }}>

                    </div>
                    <div className='productImageContainer'>
                        <img style={{ width: '100%', maxHeight: '100%' }} src={image?.data_url || require('../Assets/Images/logo.png')} />
                    </div>
                    <div className='productNameContainer'>
                        {title}
                    </div>
                    <div className='productPriceContainer'>
                        <span className='productPriceFirst'>{splitedPrice[0]}</span>
                        <span className='productPriceSecond'>,{splitedPrice[1] || '00'} ₺</span>
                    </div>
                    <div className='productQuickButtonContainer'>
                        <a onClick={_addToBasket} className='productQuickButton'>
                            <GiShoppingCart className='quickButtonIcon' /> Ekle
                        </a>
                        <a onClick={() => sendMessage(title)} className='productQuickButton'>
                            <AiOutlineWhatsApp className='quickButtonIcon' /> Bilgi Al
                        </a>
                    </div>
                </>
            )
        } else {
            return <BlankProductComponent />
        }
    }
    return (
        <div className='productContainer'>
            {_renderContent()}
        </div>
    )
}
