import React, { useRef, useState } from 'react'
import './Styles/HeaderMobileStyles.css'
import { AiOutlineSearch } from 'react-icons/ai'
import { GiShoppingCart } from 'react-icons/gi'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import NavbarMobile from './NavbarMobile'
import { useSelector } from 'react-redux'
import { BasketSelectors } from '../Sections/Basket'

export default function HeaderMobile() {
    const navigate = useNavigate()
    const wrapper = useRef()
    const containerRef = useRef()
    const leftBarRef = useRef()
    const basketProducts = useSelector(BasketSelectors.basketProducts);

    const toggleMenu = () => {
        wrapper.current.classList.toggle('open')
        containerRef.current.classList.toggle('is-nav-open')
        leftBarRef.current.classList.toggle('is-nav-open')
    }

    return (
        <div className="headerMobileContainer">
            <NavbarMobile toggleMenu={toggleMenu} containerRef={containerRef} leftBarRef={leftBarRef} />
            <div ref={wrapper} className={'side-bar'} onClick={() => toggleMenu()}>
                <span />
                <span />
                <span />
            </div>
            <div className='logoContainerMobile'>
                <img className='imgContainerMobile' src={require('../Assets/Images/logo1.png')} />
            </div>
            <Button
                number={basketProducts.length}
                onClick={() => navigate(ScreenNames.BASKET_SCREEN)}
                iconStyle={{ width: 30, height: 30 }}
                reverse
                icon={(style) => <GiShoppingCart style={style} />}
                text={null}
                className="cartMarginMobile" />

        </div>
    )
}
