import { isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../../../Constants/ScreenNames'
import './Styles/ShowcaseItemStyles.css'
import BlankCampaignComponent from '../../Products/Components/BlankCampaignComponent'

export default function ShowcaseItem(props) {
    const { screenEnum,
        title,
        image,
        description,
        data
    } = props
    const navigate = useNavigate()
    const _renderImage = () => {
        return isArray(data?.imageList) ?
            <img style={{ width: '100%', height: '100%' }} src={data?.imageList[0]?.data_url} />
            :
            <img style={{ width: '100%', height: '100%' }} src={require('../../../Assets/Images/logo.png')} />
    }

    const _renderContent = () => {
        if (data) {
            return (
                <>
                    {_renderImage()}
                    <div className='showcaseDescription' style={{
                        backgroundColor: data?.color || null,
                        whiteSpace: 'nowrap',

                    }}>
                        {data?.title}
                    </div>
                    <div className='showcaseTitle' style={{
                        backgroundColor: data?.color || null,

                    }}>
                        <p>{data?.description}</p>
                    </div>
                </>
            )
        } else {
            return <BlankCampaignComponent />
        }
    }

    return (
        <a onClick={() => {
            if (data) {
                navigate('/kampanya/id=' + data?.id)
            }
        }} className={isMobile || screenEnum === 1 ? "showcaseItemMobile showcaseHover" : screenEnum === 3 ? "showcaseItem showcaseHover" : "showcaseItemMiddle showcaseHover"}>
            {_renderContent()}
        </a>
    )
}
