/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HomeActions } from '../Sections/Home';

/**
 * @typedef {object} ServiceReturnType
 * @property {boolean} loading
 * @property {*} error
 * @property {*} data
 * @property {(args: any) => any} sendRequest
 */
/**
 * @param {object} params
 * @param {RequesterFunction} params.requesterFunc
 * @param {any} [params.initialArgs]
 * @param {boolean} [params.shouldDisplayLoading]
 * @param {boolean} [params.sendRequestOnMount]
 * @param {ErrorDisplayOptions} [params.errorDisplayOptions]
 *
 * @param {object} [params.eventHandlers]
 * @param {(data:any) => void} [params.eventHandlers.onSuccess]
 * @param {(error:any) => void} [params.eventHandlers.onError]
 * @param {() => void} [params.eventHandlers.onComplete]
 *
 * @returns {ServiceReturnType}
 */
export const useService = ({
    requesterFunc,
    initialArgs,
    sendRequestOnMount = true,
    eventHandlers,
    shouldDisplayLoading = true,
}) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const sendRequest = useCallback((args) => {
        if (shouldDisplayLoading) {
            // dispatch(HomeActions.setLoading(true));
            setLoading(true)
        }
        requesterFunc(args)
            .then((response) => {
                setData(response);
                eventHandlers?.onSuccess?.(response);
                // dispatch(HomeActions.setLoading(false));
                setLoading(false)
            })
            .catch(err => {

                setError(err);
                eventHandlers?.onError?.(err);
                // dispatch(HomeActions.setLoading(false));
                setLoading(false)
            })
            .finally(() => {
                eventHandlers?.onComplete?.();
                // dispatch(HomeActions.setLoading(false));
                setLoading(false)
            });
    }, [eventHandlers, requesterFunc, dispatch, shouldDisplayLoading]);

    useEffect(() => {
        if (sendRequestOnMount) {
            sendRequest(initialArgs);
        }
    }, []);

    return {
        error,
        data,
        sendRequest,
        loading,
    };
};
