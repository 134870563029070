import { isArray } from 'lodash';
import React from 'react'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { useDispatch } from 'react-redux';
import { BasketActions } from '..';

export default function BasketItem(props) {
    const {
        value
    } = props;
    
    const dispatch = useDispatch();

    const _getSource = () => {
        let url = '';
        if (isArray(value?.imageList)) {
            url = value?.imageList[0].data_url;
        }
        return url
    }

    const _removeItem = () => {
        dispatch(BasketActions.fetchBasketProducts({
            product: value,
            type: 'DELETE'
        }));
    }

    return (
        <div className='basketItemContainer'>
            <div style={{
                width: 90,
                height: 90,
                marginLeft: 20,
            }}>
                <img alt='' style={{ width: '100%', height: '100%' }}
                    src={_getSource()} />
            </div>
            <div className='basketItemTitle'>
                {value?.title}
            </div>

            <div className='basketItemPrice'>
                {value?.price} ₺
            </div>
            <div onClick={_removeItem} className='basketItemRemove'>
                <MdOutlineDeleteOutline className='basketItemRemoveButton' style={{
                    height: 30,
                    width: 30
                }} />
            </div>
        </div>
    )
}
