import { isArray } from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import { GiShoppingCart } from 'react-icons/gi'
import { useLocation } from 'react-router-dom'
import { ScreenSizeEnum } from '../../Constants/ScreenWidth'
import './Styles/ProductScreenStyles.css'
import Zoom from 'react-medium-image-zoom'

import 'react-medium-image-zoom/dist/styles.css'
import { sendMessage } from '../../Utils/Whatsapp'
import { BasketActions } from '../Basket'
import { useDispatch } from 'react-redux'

export default function ProductScreen() {

  const [screenEnum, setScreenEnum] = useState(window.innerWidth > 1110 ? ScreenSizeEnum.BIG : window.innerWidth < 1110 && window.innerWidth >= 810 ? ScreenSizeEnum.MIDDLE : ScreenSizeEnum.MOBILE)
  const [productData, setProductData] = useState(null);
  const { state } = useLocation()
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('resize', handleMediaQueryChange)
    const data = state?.data
    if (data) {
      const splitedPrice = data?.price ? data?.price.split('.') : [data?.price, '00'];
      data.splitedPrice = splitedPrice;
      setProductData(data)
    }
  }, [])

  const _renderPrice = () => {
    if (productData) {
      return <div className='productInfoPrice'>
        {productData?.splitedPrice[0]}
        <span>,{productData?.splitedPrice[1] || '00'} ₺</span>
      </div>
    }
  }

  const handleMediaQueryChange = (matches) => {
    if (window.innerWidth >= 1110) setScreenEnum(ScreenSizeEnum.BIG)
    else if (window.innerWidth < 1110 && window.innerWidth >= 810) setScreenEnum(ScreenSizeEnum.MIDDLE)
    else setScreenEnum(ScreenSizeEnum.MOBILE)
  }

  const _renderMainImage = () => {
    if (isArray(productData?.imageList)) {
      return <Zoom ZoomContent={CustomZoomContent}>
        <img
          alt=""
          style={{ maxWidth: 275, maxHeight: 400 }}
          src={productData?.imageList[0]?.data_url} />
      </Zoom>
    }
  }

  const _addToBasket = () => {
    dispatch(BasketActions.fetchBasketProducts({
        product: productData,
        type: 'ADD'
    }))
}

  return screenEnum === ScreenSizeEnum.MOBILE ?
    <div className='productScreenContainer'>
      <div className='productInfoContainerMobile'>
        <div className='productInfoImageContainerMobile'>
          <div className='productInfoMainImageMobile'>
            {_renderMainImage()}
          </div>
        </div>
        <div className='productInfoDetailsContainerMobile'>
          <div className='productInfoTitleMobile'>
            <span>{productData?.title}</span>
          </div>
          <div className='productInfoDetailsMobile'>
            {/* <div>Ürün Açıklaması</div> */}
            <div>Ürün Açıklaması</div>
            <div className='productInfoDescriptionMobile'>
              {productData?.content}
            </div>
            {_renderPrice()}
            <div className='productInfoButtonContainerMobile'>
              <a onClick={_addToBasket} className='productQuickButton'>
                <GiShoppingCart className='quickButtonIcon' /> Ekle
              </a>
              <a onClick={() => sendMessage(productData?.title)} className='productQuickButton'>
                <AiOutlineWhatsApp className='quickButtonIcon' /> Bilgi Al
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>



    : (
      <div className='productScreenContainer'>
        <div className='productInfoContainer'>
          <div className='productInfoImageContainer'>
            <div className='productInfoMainImage'>
              {_renderMainImage()}
            </div>
          </div>
          <div className='productInfoDetailsContainer'>
            <div className='productInfoTitle'>
              <span>{productData?.title}</span>
            </div>
            <div className='productInfoDetails'>
              {/* <div>Ürün Açıklaması</div> */}
              <div>Ürün Açıklaması</div>
              <div className='productInfoDescription'>{productData?.content}</div>
              {_renderPrice()}
              <div className='productInfoButtonContainer'>
                <a onClick={_addToBasket} className='productQuickButton'>
                  <GiShoppingCart className='quickButtonIcon' /> Ekle
                </a>
                <a onClick={() => sendMessage(productData?.title)} className='productQuickButton'>
                  <AiOutlineWhatsApp className='quickButtonIcon' /> Bilgi Al
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

const CustomZoomContent = ({
  img,
}) => {

  return img;
}