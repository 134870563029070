import { collection, query, limit, getDocs, where } from 'firebase/firestore';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { db } from '../../../firebase';
import { HomeActions, HomeSelectors, HomeTypes } from '..';
import { ProductList } from '../../../Constants/ProductList';
import { CampaignList } from '../../../Constants/CampaignList';

function* watcherInitial() {
    yield takeEvery(HomeTypes.FETCH_INITIAL, workerInitial);
}

function* workerInitial() {
    const list = yield select(HomeSelectors.productList);
    const loading = yield select(HomeSelectors.loading)
    try {
        if (list.length === 0 && !loading) {
            yield put(HomeActions.setLoading(true))
            // const productList = yield call(workerGetProductList)
            yield put(HomeActions.setProductList(ProductList));

            const campaignList = [...CampaignList];
            campaignList.sort((a, b) => a?.order - b?.order)
            yield put(HomeActions.setCampaignList(campaignList.filter(n => n.isActive === true)))
            yield put(HomeActions.setLoading(false))
        }
    } catch (error) {
        yield put(HomeActions.setLoading(false))
    }
}

function* workerGetProductList() {
    const productsRef = collection(db, 'products');
    const productQuery = query(productsRef, where('price', '!=', null), limit(20));
    const campaignQuery = query(productsRef, where('isCampaign', '==', true), limit(6));
    const querySnapshot = yield getDocs(productQuery);
    const campaignSnapshot = yield getDocs(campaignQuery);

    const campaignList = campaignSnapshot.docs.map(doc => {
        const data = doc.data();
        return data;
    });

    let lastData = null;
    const productList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        data.fbId = doc.id;
        if (data.isActive && !data?.isCampaign) {
            lastData = doc;
            return data;
        }
    });

    if (campaignList.length > 0) {
        campaignList.sort((a, b) => a?.order - b?.order)
        yield put(HomeActions.setCampaignList(campaignList.filter(n => n.isActive === true)))
    }

    return productList.filter(n => n);
}



export const Sagas = [
    fork(watcherInitial),
];
