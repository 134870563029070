/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ProductItem from '../../Components/ProductItem'
import ProductItemMobile from '../../Components/ProductItemMobile'
import ScreenNames from '../../Constants/ScreenNames'
import { ScreenSizeEnum } from '../../Constants/ScreenWidth'
import { HomeSelectors } from '../Home'
import './Styles/ProductListScreenStyles.css'
import { useService } from '../../Utils/useService'
import { getProductsByPage } from './Services/GetProductsByPage'
import { getProductsByCategoryId } from './Services/GetProductsByCategoryId'
import { isNumber } from 'lodash'

export default function ProductsScreen() {

  const [screenEnum, setScreenEnum] = useState(window.innerWidth > 1110 ? ScreenSizeEnum.BIG : window.innerWidth < 1110 && window.innerWidth >= 810 ? ScreenSizeEnum.MIDDLE : ScreenSizeEnum.MOBILE)
  const [pageIndex, setPageIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [lastDocument, setLastDocument] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [noProducts, setNoProducts] = useState(false);
  const [lastCategoryId, setLastCategoryId] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('resize', handleMediaQueryChange)
    let categoryId = pathname.split('id=')[1];
    categoryId = categoryId?.split?.('&')?.[0];
    setLastCategoryId(categoryId);
  }, [])

  const { sendRequest: getByPage, loading } = useService({
    requesterFunc: getProductsByPage,
    sendRequestOnMount: false,
    shouldDisplayLoading: true,
    eventHandlers: {
      onSuccess: (res) => {
        if (pathname === ScreenNames.PRODUCTS_SCREEN && lastCategoryId) {
          setLastCategoryId(null);
          setProducts(res?.list)
          setLastDocument(null)
          setPageIndex(0)
          setNoProducts(false)
          if (res?.list?.length < 12) {
            setLastPage(pageIndex);
          } else {
            setLastPage(null)
          }
        } else if (products?.length > 0) {
          const newList = products.concat(res?.list)
          setProducts(newList)
        } else {
          setProducts(res?.list)
        }
        if (res?.list?.length === 0) {
          setNoProducts(true);
          setLastPage(pageIndex - 1)
          setPageIndex(pageIndex - 1)
        } else if (res?.list?.length < 12) {
          setLastPage(pageIndex)
        }
        setLastDocument(res?.lastDocument)
      },
      onError: () => setNoProducts(true),
    }
  });

  const { sendRequest: getByCategory, loading: categoryLoading } = useService({
    requesterFunc: getProductsByCategoryId,
    sendRequestOnMount: false,
    shouldDisplayLoading: true,
    eventHandlers: {
      onSuccess: (res) => {
        let categoryId = pathname.split('id=')[1];
        categoryId = categoryId?.split?.('&')?.[0];
        if (lastCategoryId !== categoryId && products?.length !== 0) {
          setLastCategoryId(categoryId);
          setLastDocument(null)
          setProducts(res?.list)
          setPageIndex(0)
          setNoProducts(false)
          if (res?.list?.length < 12) {
            setLastPage(pageIndex);
          } else {
            setLastPage(null)
          }
        } else if (products?.length > 0) {
          const newList = products.concat(res?.list)
          setProducts(newList)
        } else {
          setProducts(res?.list)
        }
        if (res?.list?.length === 0) {
          setNoProducts(true);
          setLastPage(pageIndex - 1)
          setPageIndex(pageIndex - 1)
        } else if (res?.list?.length < 12) {
          setLastPage(pageIndex)
        }
        setLastDocument(res?.lastDocument)
      },
      onError: () => setNoProducts(true),
    }
  });

  useEffect(() => {
    let categoryId = pathname.split('id=')[1];
    categoryId = categoryId?.split?.('&')?.[0];

    if (pathname === ScreenNames.PRODUCTS_SCREEN) {
      if (lastCategoryId) {
        getByPage({
          lastDocument: null,
        })
      } else {
        getByPage({
          lastDocument: lastDocument,
        })
      }
    } else if (categoryId) {
      getByCategory({
        lastDocument: lastCategoryId !== categoryId ? null : lastDocument,
        categoryId: categoryId,
      })
    }
  }, [pathname])

  const handleMediaQueryChange = (matches) => {
    if (window.innerWidth >= 1110) setScreenEnum(ScreenSizeEnum.BIG)
    else if (window.innerWidth < 1110 && window.innerWidth >= 810) setScreenEnum(ScreenSizeEnum.MIDDLE)
    else setScreenEnum(ScreenSizeEnum.MOBILE)
  }

  const _renderData = () => {
    if (screenEnum !== ScreenSizeEnum.MOBILE) {
      const rendered = (products?.length === 0 && !noProducts) || (loading || categoryLoading) ? Array.from({ length: 12 }) : products.slice(0 + (pageIndex * 12), 12 + (pageIndex * 12))
      return rendered.map((value, index) => {
        return <div key={index} style={{
          margin: '10px 10px 0px 10px'
        }}>
          <ProductItem
            product={value}
            id={value?.id}
            price={value?.price}
            title={value?.title}
            image={value?.imageList[0]} />
        </div>
      })
    } else {
      const rendered = (products?.length === 0 && !noProducts) || (loading || categoryLoading) ? Array.from({ length: 12 }) : products.slice(0 + (pageIndex * 12), 12 + (pageIndex * 12))
      const lastData = []
      for (let index = 0; index < rendered.length; index = index + 2) {
        const rowData = rendered.slice(index, index + 2)
        const rowItems = rowData.map((value, index) => {
          return <div key={index} style={{
            margin: '5px 5px',
            width: '50%'
          }}>
            <ProductItemMobile
              product={value}
              id={value?.id}
              price={value?.price}
              title={value?.title}
              image={value?.imageList[0]}
            />
          </div>
        })

        lastData.push(<div style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          {rowItems}
        </div>)
      }
      return <div>
        {lastData}
      </div>;
    }
  }

  useEffect(() => {
    if (pageIndex >= products?.length / 12)
      if (products?.length % 12 === 0 && pageIndex !== 0) {
        let categoryId = pathname.split('id=')[1];
        categoryId = categoryId?.split?.('&')?.[0];
        if (pathname === ScreenNames.PRODUCTS_SCREEN) {
          getByPage({
            lastDocument: lastDocument,
          })
        } else if (categoryId) {
          getByCategory({
            categoryId: categoryId,
            lastDocument: lastDocument,
          })
        }
      }
  }, [pageIndex]);

  const _changePageNumber = (tag) => {
    if (tag === '+' && ((isNumber(lastPage) && (pageIndex < lastPage)) || !isNumber(lastPage))) {
      setPageIndex(pageIndex + 1)
    } else if (tag === '-') {
      if (pageIndex > 0) {
        setPageIndex(pageIndex - 1)
      }
    }
  }

  const _renderEmpty = () => {
    return <div style={{
      fontSize: 20
    }}>
      Aradığınız kategoride ürün bulunmamaktadır.
    </div>
  }

  const _renderPageNumbers = () => {
    return Array.from({
      length: Math.ceil(products.length / 12)
    }).map((value, index) => {
      return <option key={index} value={index + 1}>{index + 1}</option>
    })

  }

  return (
    <div className='categoryMainContainer'>

      <div className='categoryOrderMenuBar'>
        <div className='categoryTotalProducts'>
          Toplam {products.length} ürün gösteriliyor
        </div>
        {/* <div className='categoryPageNumberContainer'>
          <div className='categoryJumpPage'>
            <div>Sayfaya git: </div>
            <select onChange={_setPageNumber}>
              {_renderPageNumbers()}
            </select>
          </div>
        </div> */}
      </div>

      <div className='categoryChangePageContainer'>
        <a className={pageIndex === 0 ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('-')}>Önceki Sayfa</a>
        <a className={lastPage === pageIndex ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('+')}>Sonraki Sayfa</a>
      </div>

      <div className={`${screenEnum === ScreenSizeEnum.MOBILE ? 'categoryProductListContainerMobile' : 'categoryProductListContainer'}`}>
        {
          noProducts && products?.length === 0 ? _renderEmpty() : _renderData()
        }
      </div>

      <div className='categoryChangePageFooterContainer'>
        <a className={pageIndex === 0 ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('-')}>Önceki Sayfa</a>
        <a className={lastPage === pageIndex ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('+')}>Sonraki Sayfa</a>
      </div>
      <div className='categoryOrderFooterBar'>
        <div className='categoryTotalProducts'>
          Toplam {products.length} ürün gösteriliyor
        </div>
        {/* <div className='categoryPageNumberContainer'>
          <div className='categoryJumpPage'>
            <div>Sayfaya git: </div>
            <select onChange={_setPageNumber}>
              {_renderPageNumbers()}
            </select>
          </div>
        </div> */}
      </div>
    </div>
  )
}
