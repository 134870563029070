import React from 'react'
import { GiShoppingCart } from 'react-icons/gi'
import './Styles/ButtonStyles.css'

export default function Button(props) {
    const { text,
        icon,
        reverse,
        iconStyle,
        buttonStyle,
        className,
        rightIcon,
        rightIconStyles,
        onClick,
        number
    } = props
    return (
        <a
            onClick={onClick}
            style={buttonStyle}
            className={`${className} mainButtonIconContainer`}>
            {icon ? icon(iconStyle) : null}
            <div>{text}</div>
            {rightIcon ? rightIcon(rightIconStyles) : null}
            {
                number ?
                    <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        backgroundColor: 'rgb(59, 183, 126)',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 5,
                        fontSize: 14
                    }}>
                        {number}
                    </div> : null
            }
        </a>
    )
}
