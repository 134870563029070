import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import { TailSpin } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { BasketActions, BasketSelectors } from '.'
import { sendMessage } from '../../Utils/Whatsapp'
import AddressInfoForm from './Components/AddressInfoForm'
import BasketLoader from './Components/BasketLoader'
import { VscCheckAll } from 'react-icons/vsc'

const locationData = [
    { id: 0, text: 'İlçe Seçiniz' },
    { id: 1, text: 'Şirinevler' },
    { id: 2, text: 'Kocasinan' },
    { id: 3, text: 'Siyavuşpaşa' },
    { id: 4, text: 'Yenibosna' },
    { id: 5, text: 'Diğer' },
]

export default function BasketApproveScreen() {
    const [location, setLocation] = useState(locationData[0])
    const [orderNumber, setOrderNumber] = useState(null)
    const [orderPrice, setOrderPrice] = useState(null)

    const basketProducts = useSelector(BasketSelectors.basketProducts);
    const loading = useSelector(BasketSelectors.loading);
    const orderResult = useSelector(BasketSelectors.orderResult);

    const dispatch = useDispatch();

    const _renderItems = () => {
        return locationData.map((value, index) => {
            return <Dropdown.Item
                key={index}
                onClick={() => setLocation(value)}>
                {value.text}
            </Dropdown.Item>
        })
    };

    const _approveProducts = (userData) => {
        dispatch(BasketActions.addOrder(userData))
    }

    const navigateToWhatsapp = () => {
        sendMessage(orderNumber, true)
    }

    useEffect(() => {
        if (orderResult) {
            setOrderNumber(orderResult?.id);
            setOrderPrice(orderResult?.totalPrice);
            dispatch(BasketActions.setOrderResult(null));
        }
    }, [orderResult])

    return (
        <div style={{
            marginTop: 50,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            minHeight: 300,
        }}>
            <div style={{
                width: '70%',
                display: 'flex',
                // alignItems: 'flex-start',
                flexDirection: 'column',
                position: 'relative'
            }}>
                {
                    !orderNumber ?
                        <>
                            <BasketLoader />
                            <span style={{
                                fontSize: 20,
                            }}>Siparişin teslim edileceği ilçeyi seçiniz</span>
                            <Dropdown style={{
                                marginTop: 10
                            }}>
                                <Dropdown.Toggle style={{
                                    backgroundColor: location.id !== 5 ? 'rgb(59, 183, 126)' : 'red',
                                    borderWidth: 0
                                }} variant="success" id="dropdown-basic">
                                    {location.text}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {_renderItems()}
                                </Dropdown.Menu>
                            </Dropdown>



                            {
                                location.id !== 5 && location.id !== 0 ?
                                    <AddressInfoForm
                                        onSubmit={_approveProducts}
                                    />
                                    : location.id !== 0 ?
                                        <div style={{
                                            width: '100%',
                                            marginTop: 20,
                                            flexDirection: 'column',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            {/* <span style={{
                                                fontSize: 20,
                                                color: 'red'

                                            }}>Üzgünüz, belirttiğiniz adrese gönderim yapılamamaktadır.</span> */}
                                            <span style={{
                                                fontSize: 20,
                                                color: 'red'

                                            }}>Lütfen bizimle iletişime geçiniz.</span>
                                            <button onClick={() => { }} style={{
                                                width: 250,
                                                height: 50,
                                                backgroundColor: 'rgb(59, 183, 126)',
                                                color: 'white',
                                                borderWidth: 0,
                                                borderRadius: 10,
                                                fontSize: 18,
                                                marginTop: 10
                                            }}>
                                                İletişime Geç
                                                <AiOutlineWhatsApp style={{
                                                    width: 25,
                                                    height: 25,
                                                    marginLeft: 10,
                                                    marginBottom: 2
                                                }} />
                                            </button>
                                        </div> : null
                            }
                        </>
                        :
                        <div style={{
                            fontSize: 22
                        }}>
                            <div style={{
                                width: '100%',
                            }}>
                                <VscCheckAll style={{
                                    width: 50,
                                    height: 50,
                                    color: 'rgb(59, 183, 126)'
                                }} />
                            </div>
                            <span style={{ fontWeight: 'bold' }}>{orderNumber}</span> numaralı siparişiniz oluşturuldu.
                            <p>
                                Günsel Gündoğdu - TR31 0006 4000 0011 1780 4849 88 numaralı IBAN'a <span style={{ fontWeight: 'bold' }}>{orderPrice}₺</span> tutarındaki ödemenizi yaptıktan sonra siparişiniz hazırlanacaktır.
                            </p>
                            <p>
                                Detaylı bilgi için lütfen iletişime geçiniz.
                            </p>
                            <button onClick={navigateToWhatsapp} style={{
                                width: 250,
                                height: 50,
                                backgroundColor: 'rgb(59, 183, 126)',
                                color: 'white',
                                borderWidth: 0,
                                borderRadius: 10,
                                fontSize: 18,
                            }}>
                                İletişime Geç
                                <AiOutlineWhatsApp style={{
                                    width: 25,
                                    height: 25,
                                    marginLeft: 10,
                                    marginBottom: 2
                                }} />
                            </button>
                        </div>
                }
            </div>
        </div>
    )
}
