import React from 'react'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import './Styles/FooterMobileStyles.css'

export default function FooterMobile() {
    const navigate = useNavigate();

    return (
        <>
            <div className="footerHr" />

            <div className='footerMobileContainer'>
                <div className='footerLeftContainer'>
                    <img className='footerLogo' src={require('../Assets/Images/logo1.png')} />

                </div>
                <div className='footerMiddleContainerMobile'>
                    {/* <div>
                        <p>Anasayfa</p>
                        <p>Hakkımızda</p>
                        <p>Bize Ulaşın</p>
                    </div> */}
                    <div style={{
                        width: 150, justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <p onClick={() => {
                            navigate(ScreenNames.PRODUCTS_SCREEN + `/id=1&Saksı Bitkileri`)
                        }}>Saksı Bitkileri</p>
                        <p onClick={() => {
                            navigate(ScreenNames.PRODUCTS_SCREEN + `/id=2&Çelenk`)
                        }}>Çelenk</p>
                        <p onClick={() => {
                            navigate(ScreenNames.PRODUCTS_SCREEN + `/id=3&Buket`)
                        }}>Buket</p>
                    </div>
                    <div style={{
                        width: 150, justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {/* <p onClick={() => {
                            navigate(ScreenNames.PRODUCTS_SCREEN + `/id=4&Bitkiler`)
                        }}>Bitkiler</p> */}
                        <p onClick={() => {
                            navigate(ScreenNames.PRODUCTS_SCREEN + `/id=5&Teraryum`)
                        }}>Teraryum</p>
                        <p onClick={() => {
                            navigate(ScreenNames.PRODUCTS_SCREEN + `/id=6&Aranjman`)
                        }}>Aranjman</p>
                    </div>
                </div>
                <div className='footerRightContainerMobile'>
                <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign:'initial'
                        // alignItems:'center'
                    }}>
                        <GoLocation className='footerInfoIcon' style={{ marginTop: 15 }} />
                        <div>
                            Adres: Şirinevler Mahallesi. Saray Caddesi. No:42a - Bahçelievler/İstanbul, 34188
                        </div>
                    </div>
                    <div>
                        <AiOutlinePhone className='footerInfoIcon' />Telefon: 0(530) 783 44 78
                    </div>
                    <div>
                        <AiOutlineMail className='footerInfoIcon' /> Mail: mustafagundogdu4434@gmail.com
                    </div>
                </div>
            </div>

            <div className="footerHr" />

            <div className='footerCopyright'>
                @2022 Duru Çiçekçilik - Tüm Hakları Saklıdır.
            </div>
        </>
    )
}
