import React from 'react'
import { useSelector } from 'react-redux'
import { HomeSelectors } from '../Sections/Home'

export default function InitialLoading() {
    const loading = useSelector(HomeSelectors.loading);

    return !loading ? null : (
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            zIndex: 10,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            opacity:0.5,
        }}>
            <div className='initialLoading' style={{
                width: 300,
                height: 300,
                border: '5px solid white',
                borderTopColor: '#3bb77d',
                borderBottomColor: '#3bb77d',
                borderRadius: 200,
                position: 'absolute',
            }}>

            </div>
            <img className='footerLogo' src={require('../Assets/Images/logo1.png')} />

        </div>
    )
}
