import { collection, getDocs, limit, query, startAfter, where, orderBy } from "firebase/firestore";
import { db } from "../../../firebase";

export const getProductsByCategoryId = async (args) => {
    try {
        const categoryId = args?.categoryId;
        const productsRef = collection(db, 'products');
        const productQuery = !args?.lastDocument ? query(productsRef, where('categoryId', '==', String(categoryId)), limit(12), orderBy('id', 'desc')) : query(productsRef, where('categoryId', '==', String(categoryId)), limit(12), orderBy('id', 'desc'), startAfter(args?.lastDocument));
        const querySnapshot = await getDocs(productQuery);

        const productList = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return data;
        });

        const lastData = querySnapshot.docs[querySnapshot.docs.length - 1];

        return {
            list: productList,
            lastDocument: lastData,
        };
    }
    catch (error) {
        return null;
    }
};
