import React, { useEffect, useRef } from 'react'
import { BiCategory } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import { BsHeadset } from 'react-icons/bs'
import Button from './Button'
import './Styles/NavbarStyles.css'
import { useLocation, useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import { categories } from '../Constants/Values'

export default function Navbar() {
    const navigate = useNavigate()
    const navbarCategoryRef = useRef()
    const { pathname } = useLocation();

    const categoryToggle = () => {
        navbarCategoryRef.current.classList.toggle('nav-category-open')
    }

    useEffect(() => {
        if (navbarCategoryRef.current.classList.length > 1) {
            navbarCategoryRef.current.classList.toggle('nav-category-open')
        }
    }, [pathname])

    const _renderCategories = () => {
        return categories.map((value, index) => {
            return (
                <div key={index} onClick={() => {
                    navigate(ScreenNames.PRODUCTS_SCREEN + `/id=${value?.value}&${value?.name}`)
                }} className='navbarAllCategoriesRow'>
                    <div>{value.name}</div>
                </div>
            )
        })
    }

    return (
        <div className='navbarContainer'>
            <Button
                onClick={categoryToggle}
                className='navbarCategoryButton mainButtonIconContainer'
                buttonStyle={{ backgroundColor: '#3bb77e', color: 'white', marginLeft: 20 }}
                iconStyle={{ width: 20, height: 20, marginRight: 5 }}
                reverse
                icon={(style) => <BiCategory style={style} />}
                rightIcon={(style) => <IoIosArrowDown style={style} />}
                rightIconStyles={{ marginLeft: 5 }}
                text={"Tüm Kategoriler"} />
            <div ref={navbarCategoryRef} className='navbarAllCategoriesContainer'>
                {_renderCategories()}
                {/* <div className='navbarAllCategoriesRow'>
                    <div>Süs Bitkileri</div>
                    <div>Saksı Bitkileri</div>
                </div>
                <div className='navbarAllCategoriesRow'>
                    <div>Buketler</div>
                    <div>Çelenk</div>
                </div> */}
            </div>

            <div className='navbarButtonContainer'>
                <Button
                    onClick={() => navigate(ScreenNames.HOME_SCREEN)}
                    text="Anasayfa"
                    className='navbarMenuButton'
                    buttonStyle={{ color: 'black', marginLeft: 10 }}

                />
                <Button
                    onClick={() => navigate(ScreenNames.PRODUCTS_SCREEN)}
                    text="Ürünler"
                    className='navbarMenuButton'
                    buttonStyle={{ color: 'black', marginLeft: 10 }}

                />
                <Button
                    onClick={() => navigate(ScreenNames.ABOUT_US_SCREEN)}
                    text="Hakkımızda"
                    className='navbarMenuButton'
                    buttonStyle={{ color: 'black', marginLeft: 10 }}

                />
                <Button
                    onClick={() => navigate(ScreenNames.CONTACT_SCREEN)}
                    text="Bize Ulaşın"
                    className='navbarMenuButton'
                    buttonStyle={{ color: 'black', marginLeft: 10 }}

                />


            </div>
            <div className='contactButtonContainer'>
                <BsHeadset style={{
                    width: 50, height: 50, color: '#253d4e',
                    marginRight: 5
                }} />
                <div className='contactButtonTextContainer'>
                    <div style={{ fontSize: 20, color: '#3bb77e' }}>
                        İletişim
                    </div>
                    <div>
                        0(530) 783 44 78
                    </div>
                </div>
            </div>
        </div>
    )
}
