import React from 'react'
import './AboutUsScreenStyles.css'

export default function AboutUsScreen() {
  return (
    <div className='aboutScreenContainer'>
      <div className='aboutInfoContainer'>
        <div className='aboutTitle'>Hakkımızda</div>
        <div style={{ textAlign: 'start', fontSize: 18, fontWeight: 'bold' }}>Biz Kimiz?</div>
        <div style={{ textAlign: 'justify', margin: 20 }}>
          Duru Çiçekçilik, 10 yıla yakın hizmet deneyimi ile sizi profesyonel çiçekçilikle tanıştırıyor. Duru Çiçekçilik olarak, 2010’dan beri çiçek sektöründe kurumsal ve Premium müşterilere hizmet vermekteyiz. Hizmet anlayışımız en kaliteli çiçeklerin, alanında profesyonel uzmanlar tarafından hazırlanması ve %100 müşteri memnuniyeti ilkesiyle tarafınıza ulaştırılmasıdır. Duru Çiçekçilik, online çiçek sektöründe farklı bir noktada durmakta ve kendisini diğer çiçek markalarından ayırmaktadır. Bunun nedeni verilen hizmetin ve hizmet sonrası servisin üst düzey kalite içermesidir. Tamamen özgün ve orijinal aranjmanlar, buketler ve güller almak istiyorsanız, Duru Çiçekçilik size en iyisini sunacaktır. Sitemizdeki tasarımlarınız dışında çiçek siparişiniz olduğunda istediğiniz çiçeği hazırlayıp, resmini çekip tarafınıza yolluyor, onayını aldıktan sonra, çiçeğin tasarımını tamamlayıp, alıcısına en kısa sürede ulaştırıyoruz.
        </div>
        <div style={{ textAlign: 'start', fontSize: 18, fontWeight: 'bold' }}>Nasıl Çalışıyoruz?</div>
        <div style={{ textAlign: 'justify', margin: 20 }}>
          Belirli lokasyonlar için siparişlerimizi kendi atölyemizde, en taze çiçekleri sizler için seçerek hazırlıyor, kendi servis araçlarımız ile ilk elden teslimatını gerçekleştiriyoruz. Müşterilerimizin memnuniyetini sürekli takip ediyor ve ürünlerimizin kalitesinin her zaman üst düzeyde olması için sürekli çalışıyoruz. </div>
      </div>
    </div>
  )
}
