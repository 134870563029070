import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HomeActions, HomeSelectors } from '../Sections/Home';
import ScreenNames from '../Constants/ScreenNames';

export default function FetchData() {

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const productList = useSelector(HomeSelectors.productList);

    useEffect(() => {
        if (productList.length === 0 && pathname === ScreenNames.HOME_SCREEN) {
            dispatch(HomeActions.fetchInitial());
        }
    }, [pathname]);

    return null;
}
