import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { HomeSelectors } from '..'
import { ScreenSizeEnum } from '../../../Constants/ScreenWidth'
import ShowcaseItem from './ShowcaseItem'
import './Styles/ShowcaseContainer.css'
import BlankProductComponent from '../../Products/Components/BlankProductComponent'

// const data = [
//     {
//         id: 1,
//         name: "1.",
//         title: 'Seçili Ürünler',
//         description: 'Aynı Gün Teslim!',
//         image: require('../../../Assets/Images/ayni-gun-teslimat.png'),
//         color: '#eb6b44'
//     }, {
//         id: 2,
//         name: "2.",
//         title: 'Çiçek & Hediye',
//         description: 'Çok Satanlar!',
//         image: require('../../../Assets/Images/cok-satanlar.png'),
//         color: '#e27475'

//     }, {
//         id: 3,
//         name: "3.",
//         title: 'Doğum Günü Hediyeleri',
//         description: 'Süper Fırsatlar!',
//         image: require('../../../Assets/Images/dogum-gunu.png'),
//         color: '#66bbe0'

//     }, {
//         id: 4,
//         name: "4.",
//         title: 'En Özel Hediyeler',
//         description: 'Aradığınız Ürünler!',
//         image: require('../../../Assets/Images/en-ozel-hediye.png'),
//         color: '#7894e4'

//     }, {
//         id: 5,
//         name: "5.",
//         title: 'Tasarım Çiçekler',
//         description: 'Süper Fırsatlar!',
//         image: require('../../../Assets/Images/tasarim-cicekler.png'),
//         color: '#c65252'

//     }, {
//         id: 6,
//         name: "6.",
//         title: 'Hediye Setleri',
//         description: 'Avantajlı Fırsatlar!',
//         image: require('../../../Assets/Images/hediye-setleri.png'),
//         color: '#f38760'

//     }
// ]

export default function ShowcaseContainer(props) {
    const { screenEnum } = props
    const data = useSelector(HomeSelectors.campaignList);

    const _renderItems = (rowData) => {
        return rowData.map((value, index) => {
            return <ShowcaseItem key={index} data={value} screenEnum={screenEnum} />
        })
    }

    const _renderData = () => {
        const list = data?.length > 0 ? data : Array.from({length:6})
        return list.map((value, index) => {
            let count = screenEnum === ScreenSizeEnum.BIG ? 3 : 2
            if (index % count === 0) {
                return <div key={index} className='showcaseContainer'>
                    {_renderRows(index)}
                </div>
            }
        })
    }

    const _renderRows = (index) => {
        const list = data?.length > 0 ? data : Array.from({length:6})
        let count = screenEnum === ScreenSizeEnum.BIG ? 3 : 2
        return _renderItems(list.slice(index, index + count))
    }

    return (
        <div className='showcaseMainContainer'>
            <div className={'showcaseSubContainer'}>
                {_renderData()}

            </div>
        </div>
    )
}
