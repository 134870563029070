import './App.css';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
import HomeScreen from './Sections/Home/HomeScreen';

import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route
} from "react-router-dom";
import BasketScreen from './Sections/Basket/BasketScreen';
import AboutUsScreen from './Sections/AboutUs/AboutUsScreen';
import ContactScreen from './Sections/Contact/ContactScreen';
import ProductsScreen from './Sections/Products/ProductsScreen';
import ProductScreen from './Sections/Products/ProductScreen';
import ScreenNames from './Constants/ScreenNames';
import HeaderMobile from './Components/HeaderMobile';
import { useEffect, useState } from 'react';
import { ScreenSizeEnum } from './Constants/ScreenWidth';
import Footer from './Components/Footer';
import FooterMobile from './Components/FooterMobile';
import { Provider } from 'react-redux';
import CreateStore from './Redux/CreateStore';
import ScrollToTop from './Components/ScrollToTop';
import ProductAdded from './Components/ProductAdded';
import BasketApproveScreen from './Sections/Basket/BasketApproveScreen';
import FetchData from './Components/FetchData';
import InitialLoading from './Components/InitialLoading';
import CampaignScreen from './Sections/Products/CampaignScreen';


const { store } = CreateStore();

function App() {
  const [screenEnum, setScreenEnum] = useState(window.innerWidth > 1110 ? ScreenSizeEnum.BIG : window.innerWidth < 1110 && window.innerWidth >= 810 ? ScreenSizeEnum.MIDDLE : ScreenSizeEnum.MOBILE)

  useEffect(() => {
    window.addEventListener('resize', handleMediaQueryChange)
    // dispatch(HomeActions.fetchInitial())
  }, [])

  const handleMediaQueryChange = (matches) => {
    if (window.innerWidth >= 1110) setScreenEnum(ScreenSizeEnum.BIG)
    else if (window.innerWidth < 1110 && window.innerWidth >= 810) setScreenEnum(ScreenSizeEnum.MIDDLE)
    else setScreenEnum(ScreenSizeEnum.MOBILE)
  }

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  const renderHeader = () => {
    if (screenEnum === ScreenSizeEnum.BIG)
      return <>
        <Header />
        <Navbar />
      </>
    return <>
      <HeaderMobile />
    </>
  }

  const renderFooter = () => {
    if (screenEnum === ScreenSizeEnum.BIG)
      return <Footer />
    return <FooterMobile />
  }

  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <ProductAdded />
          <FetchData />
          <InitialLoading/>
          {renderHeader()}
          <Routes>
            <Route exact path={ScreenNames.HOME_SCREEN} element={<HomeScreen />} />
            <Route onUpdate={scrollToTop} path={ScreenNames.PRODUCTS_SCREEN} element={<ProductsScreen />} />
            <Route onUpdate={scrollToTop} path={ScreenNames.PRODUCTS_SCREEN + '/:categoryId'} element={<ProductsScreen />} />
            <Route onUpdate={scrollToTop} path={'/kampanya/:campaignId'} element={<CampaignScreen />} />
            <Route path={ScreenNames.BASKET_SCREEN} element={<BasketScreen />} />
            <Route path={ScreenNames.BASKET_APPROVE_SCREEN} element={<BasketApproveScreen />} />
            <Route path={ScreenNames.ABOUT_US_SCREEN} element={<AboutUsScreen />} />
            <Route path={ScreenNames.CONTACT_SCREEN} element={<ContactScreen />} />
            <Route path={ScreenNames.PRODUCT_SCREEN + ':productId'} element={<ProductScreen />} />
          </Routes>
          {renderFooter()}
        </Router>

      </div>
    </Provider>
  )
}

export default App;
