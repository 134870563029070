export const NS = 'home';

const INITIAL_STATE = {
    initial: true,
    loading: false,
    productList: [],
    campaignList: []
};

export const Types = {
    FETCH_INITIAL: NS + '/fetch-initial',
    SET_LOADING: NS + '/set-loading',
    SET_PRODUCT_LIST: NS + '/set-product-list',
    SET_CAMPAIGN_LIST: NS + '/set-campaign-list'

};

export const Selectors = {
    initial: state => state[NS].initial,
    loading: state => state[NS].loading,
    productList: state => state[NS].productList,
    campaignList: state => state[NS].campaignList,
};

export const ActionCreators = {
    fetchInitial: () => ({
        type: Types.FETCH_INITIAL,
    }),
    setLoading: (data) => ({
        type: Types.SET_LOADING,
        payload: data
    }),
    setProductList: (data) => ({
        type: Types.SET_PRODUCT_LIST,
        payload: data
    }),
    setCampaignList: (data) => ({
        type: Types.SET_CAMPAIGN_LIST,
        payload: data
    }),
};

export const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_INITIAL:
            return {
                ...state,
            };
        case Types.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case Types.SET_CAMPAIGN_LIST:
            return {
                ...state,
                campaignList: action.payload,
            };
        case Types.SET_PRODUCT_LIST:
            return {
                ...state,
                productList: action.payload
            };
        default:
            return state;
    }
};
