import React, { useEffect, useState } from 'react'

import './BlankProductComponentStyles.css';
import { ScreenSizeEnum } from '../../../Constants/ScreenWidth';

export default function BlankProductComponent() {
    const [screenEnum, setScreenEnum] = useState(window.innerWidth > 1110 ? ScreenSizeEnum.BIG : window.innerWidth < 1110 && window.innerWidth >= 810 ? ScreenSizeEnum.MIDDLE : ScreenSizeEnum.MOBILE)

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        if (window.innerWidth >= 1110) setScreenEnum(ScreenSizeEnum.BIG)
        else if (window.innerWidth < 1110 && window.innerWidth >= 810) setScreenEnum(ScreenSizeEnum.MIDDLE)
        else setScreenEnum(ScreenSizeEnum.MOBILE)
    }

    return (
        <ul className={screenEnum === ScreenSizeEnum.MOBILE ? 'ulMobile' : 'ul ulNotMobile'}>
            <li className={screenEnum === ScreenSizeEnum.MOBILE ? 'liMobile' : 'li'}>
                <span className='span'>
                </span>
                <span className='span'></span>
                <span className='span'></span>
            </li>
            <li></li>
            <li></li>
        </ul>
    )
}
