import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BasketActions, BasketSelectors } from '../Sections/Basket'

export default function ProductAdded() {

    const ref = useRef();

    const added = useSelector(BasketSelectors.added);
    const dispatch = useDispatch();

    useEffect(() => {
        if (added) {
            ref.current.classList.add("active");
            setTimeout(() => {
                dispatch(BasketActions.setAdded(false));
                ref.current.classList.remove("active");

            }, 3000);
        }
    }, [added])

    return <div className='productAdded' ref={ref}>
        1 ürün sepetinize eklendi.
    </div>
}
