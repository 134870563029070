import { addDoc, collection, getDocs } from 'firebase/firestore';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { db } from '../../../firebase';
import { BasketActions, BasketSelectors, BasketTypes } from '..';

function* watcherBasket() {
    yield takeEvery(BasketTypes.FETCH_BASKET_PRODUCTS, workerBasket);
}

function* watcherOrder() {
    yield takeEvery(BasketTypes.ADD_ORDER, workerAddOrder);
}

function* workerAddOrder(action) {
    try {
        yield put(BasketActions.setLoading(true))
        const data = yield action.payload;
        const date = new Date();
        data.id = Date.now();
        data.date = date.toLocaleString('tr-TR');

        const products = yield select(BasketSelectors.basketProducts);
        let totalPrice = 0
        const productList = products.map((value) => {
            totalPrice += parseFloat(value?.price)
            return ({
                id: value?.id,
                fbId: value?.fbId,
                title: value?.title,
                price: value?.price
            })
        })
        data.products = productList;
        data.completed = false;
        data.totalPrice = totalPrice;

        yield addDoc(collection(db, "orders"), data);

        yield put(BasketActions.setOrderResult(data))
        yield put(BasketActions.setBasketProducts([]))
        yield put(BasketActions.setLoading(false))

    } catch (error) {
        yield put(BasketActions.setLoading(false))

    }
}

function* workerBasket(action) {
    const actionType = action.payload.type; //ADD - DELETE
    const product = action.payload.product;
    const basketProducts = yield select(BasketSelectors.basketProducts);

    try {
        const newList = basketProducts.slice();

        if (actionType === 'ADD') {
            newList.push(product);
            yield put(BasketActions.setAdded(true));
        } else {
            const data = newList.find(x => x.fbId === product.fbId);
            const index = newList.indexOf(data);

            if (index > -1) {
                newList.splice(index, 1);
            }
        }
        yield put(BasketActions.setBasketProducts(newList))
    } catch (error) {

    }
}

export const Sagas = [
    fork(watcherBasket),
    fork(watcherOrder),
];
