import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAZ8TzfjFhixC9D8kGVQorRInCZoW2RdT0",
  authDomain: "manager-2a479.firebaseapp.com",
  databaseURL: "https://manager-2a479.firebaseio.com",
  projectId: "manager-2a479",
  storageBucket: "manager-2a479.appspot.com",
  messagingSenderId: "664024051082",
  appId: "1:664024051082:web:00cb041450a7f777776c73"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export {
    db
}