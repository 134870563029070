/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ProductItem from '../../Components/ProductItem'
import ProductItemMobile from '../../Components/ProductItemMobile'
import { ScreenSizeEnum } from '../../Constants/ScreenWidth'
import { HomeSelectors } from '../Home'
import './Styles/ProductListScreenStyles.css'
import { useService } from '../../Utils/useService'
import { getProductsByCampaignId } from './Services/GetProductsByCampaignId.js'

export default function CampaignScreen() {
    // const productList = useSelector(HomeSelectors.productList)

    const [screenEnum, setScreenEnum] = useState(window.innerWidth > 1110 ? ScreenSizeEnum.BIG : window.innerWidth < 1110 && window.innerWidth >= 810 ? ScreenSizeEnum.MIDDLE : ScreenSizeEnum.MOBILE)
    const [pageIndex, setPageIndex] = useState(0);
    const [products, setProducts] = useState([]);
    const [lastDocument, setLastDocument] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [noProducts, setNoProducts] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const { sendRequest, loading } = useService({
        requesterFunc: getProductsByCampaignId,
        sendRequestOnMount: false,
        shouldDisplayLoading: true,
        eventHandlers: {
            onSuccess: (res) => {
                if (products?.length > 0) {
                    const newList = products.concat(res?.list)
                    setProducts(newList)
                } else {
                    setProducts(res?.list)
                }
                if (res?.list?.length === 0) {
                    setNoProducts(true);
                    setLastPage(pageIndex - 1)
                    setPageIndex(pageIndex - 1)
                } else if (res?.list?.length < 12) {
                    setLastPage(pageIndex)
                }
                setLastDocument(res?.lastDocument)
            },
            onError: () => setNoProducts(true),
        }
    });


    useEffect(() => {
        const categoryId = pathname.split('kampanya/id=')[1]
        if (categoryId) {
            sendRequest({
                campaignId: categoryId
            })
        }
    }, [pathname])

    const handleMediaQueryChange = (matches) => {
        if (window.innerWidth >= 1110) setScreenEnum(ScreenSizeEnum.BIG)
        else if (window.innerWidth < 1110 && window.innerWidth >= 810) setScreenEnum(ScreenSizeEnum.MIDDLE)
        else setScreenEnum(ScreenSizeEnum.MOBILE)
    }

    useEffect(() => {
        if (pageIndex >= products?.length / 12)
            if (products?.length % 12 === 0 && pageIndex !== 0) {
                const campaignId = pathname.split('kampanya/id=')[1]
                sendRequest({
                    campaignId: campaignId,
                    lastDocument: lastDocument
                });
            }
    }, [pageIndex]);

    const _renderData = () => {
        if (screenEnum !== ScreenSizeEnum.MOBILE) {
            const rendered = (products?.length === 0 && !noProducts) || loading ? Array.from({ length: 12 }) : products?.slice(0 + (pageIndex * 12), 12 + (pageIndex * 12))
            return rendered?.map((value, index) => {
                return <div key={index} style={{
                    margin: '10px 10px 0px 10px'
                }}>
                    <ProductItem
                        product={value}
                        id={value?.id}
                        price={value?.price}
                        title={value?.title}
                        image={value?.imageList[0]} />
                </div>
            })
        } else {
            const rendered = (products?.length === 0 && !noProducts) || loading ? Array.from({ length: 12 }) : products?.slice(0 + (pageIndex * 12), 12 + (pageIndex * 12))
            const lastData = []
            for (let index = 0; index < rendered?.length; index = index + 2) {
                const rowData = rendered?.slice(index, index + 2)
                const rowItems = rowData?.map((value, index) => {
                    return <div key={index} style={{
                        margin: '5px 5px',
                        width: '50%'
                    }}>
                        <ProductItemMobile
                            product={value}
                            id={value?.id}
                            price={value?.price}
                            title={value?.title}
                            image={value?.imageList[0]}
                        />
                    </div>
                })

                lastData.push(<div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {rowItems}
                </div>)
            }
            return <div>
                {lastData}
            </div>;
        }
    }

    const _changePageNumber = (tag) => {
        if (tag === '+' && ((lastPage && (pageIndex < lastPage)) || !lastPage)) {
            setPageIndex(pageIndex + 1)
        } else if (tag === '-') {
            if (pageIndex > 0) {
                setPageIndex(pageIndex - 1)
            }
        }
    }

    const _renderEmpty = () => {
        return <div style={{
            fontSize: 20
        }}>
            Aradığınız kategoride ürün bulunmamaktadır.
        </div>
    }

    return (
        <div className='categoryMainContainer'>

            <div className='categoryOrderMenuBar'>
                <div className='categoryTotalProducts'>
                    Toplam {products?.length} ürün gösteriliyor
                </div>
                {/* <div className='categoryPageNumberContainer'>
                    <div className='categoryJumpPage'>
                        <div>Sayfaya git: </div>
                        <select onChange={_setPageNumber}>
                            {_renderPageNumbers()}
                        </select>
                    </div>
                </div> */}
            </div>

            <div className='categoryChangePageContainer'>
                <a className={pageIndex === 0 ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('-')}>Önceki Sayfa</a>
                <a className={lastPage === pageIndex ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('+')}>Sonraki Sayfa</a>
            </div>

            <div className={`${screenEnum === ScreenSizeEnum.MOBILE ? 'categoryProductListContainerMobile' : 'categoryProductListContainer'}`}>
                {
                    noProducts && products?.length === 0 ? _renderEmpty() : _renderData()
                }
            </div>

            <div className='categoryChangePageFooterContainer'>
                <a className={pageIndex === 0 ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('-')}>Önceki Sayfa</a>
                <a className={lastPage === pageIndex ? 'categoryChangePageButton categoryChangePageButtonDisabled' : 'categoryChangePageButton'} onClick={() => _changePageNumber('+')}>Sonraki Sayfa</a>
            </div>
            <div className='categoryOrderFooterBar'>
                <div className='categoryTotalProducts'>
                    Toplam {products?.length} ürün gösteriliyor
                </div>
                {/* <div className='categoryPageNumberContainer'>
                    <div className='categoryJumpPage'>
                        <div>Sayfaya git: </div>
                        <select onChange={_setPageNumber}>
                            {_renderPageNumbers()}
                        </select>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
