import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { AiOutlineWhatsApp } from 'react-icons/ai';

export default function AddressInfoForm({
    onSubmit
}) {

    const [senderName, setSenderName] = useState('');
    const [receiverName, setReceiverName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [note, setNote] = useState('');
    const [showInvalid, setShowInvalid] = useState(false);


    const _setSenderName = (e) => {
        const name = e.target.value;
        setSenderName(name);
    }

    const _setReceiverName = (e) => {
        const name = e.target.value;
        setReceiverName(name);
    }

    const _setPhone = (e) => {
        const name = e.target.value;
        setPhone(name);
    }

    const _setNote = (e) => {
        const name = e.target.value;
        setNote(name);
    }

    const _setAddress = (e) => {
        const name = e.target.value;
        setAddress(name);
    }

    const _getIsInvalid = (text) => {
        if (showInvalid && !text) {
            return true
        }
    }

    const _completeOrder = () => {
        if (note && senderName && receiverName && address && phone) {
            onSubmit({
                senderName: senderName,
                receiverName: receiverName,
                address: address,
                note: note,
                phone: phone
            })
        } else {
            setShowInvalid(true)
        }
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                marginTop: 20,
                justifyContent: 'center',
            }}>
                <Form.Group style={{ width: '20%' }} className="mb-3" controlId="name">
                    <Form.Control
                        isInvalid={_getIsInvalid(senderName)}
                        value={senderName}
                        onChange={_setSenderName}
                        placeholder="Gönderen Ad & Soyad" />
                </Form.Group>
                <Form.Group style={{ width: '20%', marginLeft: '2%' }} className="mb-3" controlId="name">
                    <Form.Control
                        isInvalid={_getIsInvalid(receiverName)}
                        value={receiverName}
                        onChange={_setReceiverName}
                        placeholder="Alıcı Ad & Soyad" />
                </Form.Group>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Form.Group style={{ width: '42%' }} className="mb-3" controlId="phone">
                    <Form.Control
                        isInvalid={_getIsInvalid(phone)}
                        value={phone}
                        onChange={_setPhone}
                        placeholder="Telefon Numarası" />
                </Form.Group>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Form.Group style={{ width: '42%' }} className="mb-3" controlId="address">
                    <Form.Control
                        value={address}
                        onChange={_setAddress}
                        isInvalid={_getIsInvalid(address)} required as='textarea' placeholder="Teslimat adresi" />
                </Form.Group>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Form.Group style={{ width: '42%', position: 'relative' }} className="mb-3" controlId="note">
                    <Form.Control
                        isInvalid={_getIsInvalid(note)}
                        value={note}
                        onChange={_setNote}
                        maxLength={100} style={{ height: 150, resize: 'none' }} as='textarea' placeholder="Not (İsteğe bağlı.)" >

                    </Form.Control>
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        color: 'gray',
                        right: 5,
                    }}>
                        {note.length}/100
                    </div>
                </Form.Group>
            </div>

            <div style={{
                width: '100%',
                // marginTop: 20,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {/* <span style={{
                    fontSize: 20,

                }}>Alışverişinizi whatsapp üzerinden tamamlamak için tıklayın</span> */}
                <button onClick={() => _completeOrder()} style={{
                    width: 250,
                    height: 50,
                    backgroundColor: 'rgb(59, 183, 126)',
                    color: 'white',
                    borderWidth: 0,
                    borderRadius: 10,
                    fontSize: 18,
                    // marginTop: 10
                }}>
                    Sipariş Oluştur
                    {/* <AiOutlineWhatsApp style={{
                        width: 25,
                        height: 25,
                        marginLeft: 10,
                        marginBottom: 2
                    }} /> */}
                </button>
            </div>
        </div>
    )
}
