import React from 'react'
import { useSelector } from 'react-redux'
import { BasketSelectors } from '.'
import BasketItem from './Components/BasketItem'
import './Styles/BasketScreenStyles.css'
import { HiShoppingCart } from 'react-icons/hi'
import Button from '../../Components/Button'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../../Constants/ScreenNames'

export default function BasketScreen() {
  const basketProducts = useSelector(BasketSelectors.basketProducts);
  const navigate = useNavigate();

  const _renderItems = () => {
    return basketProducts.map((value, index) => {
      return <BasketItem key={index} value={value} />
    })
  }

  const _getTotalPrice = () => {
    let price = 0;
    basketProducts.map(value => {
      price += parseInt(value?.price);
    })
    return price;
  }

  const _renderEmpty = () => {
    return (
      <>
        <div className='basketEmptyTitle'>
          <div className='basketEmptyIconContainer'>
            <HiShoppingCart style={{
              color: 'rgb(59, 183, 126)',
              width: 50,
              height: 50
            }} />
          </div>

          <p>Sepetinizde ürün bulunmamaktadır.</p>
          <a onClick={() => navigate(ScreenNames.HOME_SCREEN)} style={{
            backgroundColor: 'rgb(59, 183, 126)',
            padding: 20,
            borderRadius: 10,
            color: 'white',
            cursor: 'pointer'
          }}>
            Alışverişe Başla
          </a>
        </div>
      </>

    )
  }

  return basketProducts.length === 0 ? _renderEmpty() : (
    <div className='basketPageContainer'>
      <div className='basketPageTitle'>
        <div>Sepetim ({basketProducts.length} ürün)</div>
      </div>
      <div className='basketPageSubContainer'>
        {_renderItems()}
        <div style={{
          width: '90%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 20
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 20
          }}>
            Toplam Fiyat: {_getTotalPrice()} ₺
          </div>
          <button onClick={() => navigate('/')} style={{
            backgroundColor: 'white',
            color: 'black',
            padding: 10,
            borderRadius: 10,
            marginRight: 10,
            border: '1px solid rgb(59, 183, 126)'
          }}>
            Alışverişe Devam Et
          </button>
          <button onClick={() => navigate(ScreenNames.BASKET_APPROVE_SCREEN)} style={{
            backgroundColor: 'rgb(59, 183, 126)',
            borderWidth: 0,
            color: 'white',
            padding: 10,
            borderRadius: 10
          }}>
            Sepeti Onayla
          </button>
        </div>

      </div>
    </div>
  )
}
