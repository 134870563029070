export const NS = 'basket';

const INITIAL_STATE = {
    basketProducts: [],
    added: false,
    loading: false,
    orderResult: null
};

export const Types = {
    SET_BASKET_PRODUCTS: NS + '/set-basket-products',
    FETCH_BASKET_PRODUCTS: NS + '/fetch-basket-products',
    SET_ADDED: NS + '/set-added',
    ADD_ORDER: NS + '/add-order',
    SET_LOADING: NS + '/set-loading',
    SET_ORDER_RESULT: NS + '/set-order-result'
};

export const Selectors = {
    basketProducts: state => state[NS].basketProducts,
    added: state => state[NS].added,
    loading: state => state[NS].loading,
    orderResult: state => state[NS].orderResult,
};

export const ActionCreators = {
    fetchBasketProducts: (data) => ({
        type: Types.FETCH_BASKET_PRODUCTS,
        payload: data
    }),
    setBasketProducts: (data) => ({
        type: Types.SET_BASKET_PRODUCTS,
        payload: data
    }),
    setAdded: (data) => ({
        type: Types.SET_ADDED,
        payload: data
    }),
    addOrder: (data) => ({
        type: Types.ADD_ORDER,
        payload: data
    }),
    setLoading: (data) => ({
        type: Types.SET_LOADING,
        payload: data
    }),
    setOrderResult: (data) => ({
        type: Types.SET_ORDER_RESULT,
        payload: data
    })
};

export const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.SET_BASKET_PRODUCTS:
            return {
                ...state,
                basketProducts: action.payload
            };
        case Types.SET_ADDED:
            return {
                ...state,
                added: action.payload
            };
        case Types.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case Types.SET_ORDER_RESULT:
            return {
                ...state,
                orderResult: action.payload
            };
        case Types.FETCH_BASKET_PRODUCTS:
            return {
                ...state,
            };
        default:
            return state;
    }
};
