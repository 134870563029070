import { all } from 'redux-saga/effects';
import { BasketSagas } from '../Sections/Basket';
import { HomeSagas } from '../Sections/Home';

export default function* rootSaga() {
    yield all([
        ...HomeSagas,
        ...BasketSagas,
    ]);
}
