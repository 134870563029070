const ScreenNames = {
    HOME_SCREEN: "/",
    ABOUT_US_SCREEN: "/hakkimizda",
    CONTACT_SCREEN: "/iletisim",
    BASKET_SCREEN: "/sepet",
    BASKET_APPROVE_SCREEN: "/sepet/onay",
    PRODUCTS_SCREEN: "/urunler",
    PRODUCT_SCREEN: "/urun/"
}

export default ScreenNames