import React, { useRef } from 'react'
import { BsHeadset } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import { categories } from '../Constants/Values'
import Search from './Search'
import './Styles/NavbarMobileStyles.css'

export default function NavbarMobile(props) {
    const { containerRef, leftBarRef, toggleMenu } = props

    const navigate = useNavigate()
    const categoryRef = useRef()

    const categoryToggle = () => {
        categoryRef.current.classList.toggle('category-open')
    }

    const categoryToggleClose = () => {
        let classList = categoryRef.current.classList
        if (classList[1] !== undefined)
            categoryRef.current.classList.toggle('category-open')
    }

    const _renderCategories = () => {
        return categories.map((value, index) => {
            return <div onClick={() => {
                toggleMenu()
                navigate(ScreenNames.PRODUCTS_SCREEN + `/id=${value?.value}&${value?.name}`)
            }
            } key={index}>{value.name}</div>
        })
    }

    return (
        <a onClick={() => { }} ref={containerRef} className='navbarMobileContainer'>
            <div ref={leftBarRef} className='navbarMobileLeftContainer'>
                <div className='logoContainerNavMobile'>
                    <img className='imgContainer' src={require('../Assets/Images/logo1.png')} />
                </div>
                {/* <Search className='searchContainerNavbarMobile' /> */}
                <div className='navMobileBody'>
                    <div className='navMobileMenuContainer'>
                        <a onClick={() => {
                            toggleMenu()
                            categoryToggleClose()
                            navigate(ScreenNames.HOME_SCREEN)
                        }}
                            className='navMobileMenuItem'>
                            Anasayfa
                        </a>
                        <div className='navMobileMenuBorder'></div>
                        <a onClick={() => {
                            toggleMenu()
                            categoryToggleClose()
                            navigate(ScreenNames.PRODUCTS_SCREEN)
                        }}
                            className='navMobileMenuItem'>
                            Ürünler
                        </a>
                        <div className='navMobileMenuBorder'></div>
                        <a onClick={() => {
                            // toggleMenu()
                            categoryToggle()
                            // navigate(ScreenNames.PRODUCTS_SCREEN)
                        }}
                            className='navMobileMenuItem'>
                            Kategoriler
                        </a>
                        <div ref={categoryRef} className='navMobileCategories'>
                            {_renderCategories()}
                        </div>
                        <div className='navMobileMenuBorder'></div>

                        <a onClick={() => {
                            toggleMenu()
                            categoryToggleClose()
                            navigate(ScreenNames.ABOUT_US_SCREEN)
                        }}
                            className='navMobileMenuItem'>
                            Hakkımızda
                        </a>                        <div className='navMobileMenuBorder'></div>

                        <a onClick={() => {
                            toggleMenu()
                            categoryToggleClose()
                            navigate(ScreenNames.CONTACT_SCREEN)
                        }}
                            className='navMobileMenuItem'>
                            Bize Ulaşın
                        </a>
                    </div>
                    <div className='contactButtonContainerMobile'>
                        <BsHeadset style={{
                            width: 50, height: 50, color: '#253d4e',
                            marginRight: 5
                        }} />
                        <div className='contactButtonTextContainer'>
                            <div style={{ fontSize: 20, color: '#3bb77e' }}>
                                İletişim
                            </div>
                            <div>
                                0(530) 783 44 78
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <a onClick={() => {
                toggleMenu()
                categoryToggleClose()
            }} className='navBackDropArea'>

            </a>

        </a>
    )
}
