import React, { useEffect, useRef, useState } from 'react'
import './Styles/HomeStyles.css'
import ShowcaseContainer from './Components/ShowcaseContainer'
import ProductItem from '../../Components/ProductItem'
import { ScreenSizeEnum } from '../../Constants/ScreenWidth'
import ProductItemMobile from '../../Components/ProductItemMobile'
import { useSelector } from 'react-redux'
import { HomeSelectors } from '.'
import { ProductList } from '../../Constants/ProductList'
import { getProducts } from '../Products/Services/GetProducts'
import { useService } from '../../Utils/useService'

export default function HomeScreen() {

    const [screenEnum, setScreenEnum] = useState(window.innerWidth > 1110 ? ScreenSizeEnum.BIG : window.innerWidth < 1110 && window.innerWidth >= 810 ? ScreenSizeEnum.MIDDLE : ScreenSizeEnum.MOBILE)
    const productList = useSelector(HomeSelectors.productList);
    const [products, setProducts] = useState(ProductList);
    const [noProducts, setNoProducts] = useState(false);
    const [lastDocument, setLastDocument] = useState(null);

    const ref = useRef();


    useEffect(() => {
        if (productList?.length > 0) {
            setProducts(productList);
        }
    }, [productList])

    const { sendRequest: getByPage, loading } = useService({
        requesterFunc: getProducts,
        sendRequestOnMount: productList?.length === 0,
        shouldDisplayLoading: false,
        initialArgs: {
            lastDocument: lastDocument
        },
        eventHandlers: {
            onSuccess: (res) => {
                if (products?.length > 0) {
                    const newList = products.concat(res?.list)
                    setProducts(newList)
                } else {
                    setProducts(res?.list)
                }
                if (res?.list?.length === 0) {
                    setNoProducts(true);
                }
                setLastDocument(res?.lastDocument)
            },
            onError: () => setNoProducts(true),
        }
    });

    useEffect(() => {
        if (lastDocument) {
            // getByPage({
            //     lastDocument: lastDocument
            // })
        }
    }, [lastDocument])

    useEffect(() => {
        const listInnerElement = listInnerRef.current;

        window.addEventListener('resize', handleMediaQueryChange)

        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);

            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [])

    const handleMediaQueryChange = (matches) => {
        if (window.innerWidth >= 1110) setScreenEnum(ScreenSizeEnum.BIG)
        else if (window.innerWidth < 1110 && window.innerWidth >= 810) setScreenEnum(ScreenSizeEnum.MIDDLE)
        else setScreenEnum(ScreenSizeEnum.MOBILE)
    }




    const _renderData = () => {
        if (screenEnum !== ScreenSizeEnum.MOBILE) {
            const rendered = (products?.length === 0 && !noProducts) ? Array.from({ length: 12 }) : products
            return rendered.map((value, index) => {
                return <div key={index} style={{
                    margin: '10px 10px 0px 10px'
                }}>
                    <ProductItem
                        product={value}
                        id={value?.id}
                        price={value?.price}
                        title={value?.title}
                        image={value?.imageList[0]} />
                </div>
            })
        } else {
            const rendered = (products?.length === 0 && !noProducts) ? Array.from({ length: 12 }) : products
            const lastData = []
            for (let index = 0; index < rendered.length; index = index + 2) {
                const rowData = rendered.slice(index, index + 2)
                const rowItems = rowData.map((value, index) => {
                    return <div key={index} style={{
                        margin: '5px 5px',
                        width: '50%'
                    }}>
                        <ProductItemMobile
                            product={value}
                            id={value?.id}
                            price={value?.price}
                            title={value?.title}
                            image={value?.imageList[0]}
                        />
                    </div>
                })

                lastData.push(<div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {rowItems}
                </div>)
            }
            return <div>
                {lastData}
            </div>;
        }
    }

    const _renderShowcaseProducts = () => {
        const list = productList?.length > 0 ? productList : Array.from({ length: 12 })
        if (screenEnum === 3) {
            return list.slice(0, 4).map((value, index) => {
                return <ProductItem
                    key={index}
                    product={value}
                    id={value?.id}
                    price={value?.price}
                    title={value?.title}
                    image={value?.imageList[0]} />
            })
        } else if (screenEnum === 2) {
            return list.slice(0, 3).map((value, index) => {
                return <ProductItem
                    key={index}
                    product={value}
                    id={value?.id}
                    price={value?.price}
                    title={value?.title}
                    image={value?.imageList[0]} />
            })
        } else {
            return list.slice(0, 2).map((value, index) => {
                return <ProductItemMobile
                    key={index}
                    product={value}
                    containerStyle={
                        {
                            width: '50%',
                            maxWidth: 230,
                        }
                    }
                    id={value?.id}
                    price={value?.price}
                    title={value?.title}
                    image={value?.imageList[0]} />
            })
        }

    }

    const listInnerRef = useRef();

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
            }
        }
    };
    return (
        <div className='mainContent'>
            {/* <div className='carouselContainer'>
                <CarouselComponent />
            </div> */}
            <ShowcaseContainer screenEnum={screenEnum} />
            <div className='topSellerContainer'>

                <p className='topSellerTitle'> ÜRÜNLERİMİZ</p>
                {/* <div className='topSellerProductContainer'> */}
                <div ref={listInnerRef} className={`${screenEnum === ScreenSizeEnum.MOBILE ? 'categoryProductListContainerMobile' : 'categoryProductListContainer'}`}>

                    {/* {_renderShowcaseProducts()} */}
                    {_renderData()}
                </div>
            </div>

        </div>
    )
}
