import React from 'react'
import { GiShoppingCart } from 'react-icons/gi'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import './Styles/ProductItem.css'
import { useNavigate } from 'react-router-dom'
import ScreenNames from '../Constants/ScreenNames'
import BlankProductComponent from '../Sections/Products/Components/BlankProductComponent'
import { isMobile } from 'react-device-detect'

export default function ProductItemMobile({
    title,
    price,
    id,
    image,
    product,
    containerStyle
}) {
    const navigate = useNavigate()
    const splitedPrice = price ? price.split('.') : [price, 0];

    const _onClick = () => {
        navigate(ScreenNames.PRODUCT_SCREEN + `${id}`,
            {
                state: {
                    data: product
                }
            })
    };

    const _renderContent = () => {
        if (id) {
            return (
                <>
                    <div className='productImageContainerMobile'>
                        <img style={{ width: '100%', height: '100%' }} src={image?.data_url || require('../Assets/Images/logo.png')} />
                    </div>
                    <div className='productNameContainer'>
                        {title}
                    </div>
                    <div className='productPriceContainer'>
                        <span className='productPriceFirst'>{splitedPrice[0]}</span>
                        <span className='productPriceSecond'>,{splitedPrice[1] || '00'} ₺</span>
                    </div>
                    <div className='productQuickButtonContainerMobile'>
                        <a href='#' className='productQuickButtonMobile'>
                            <GiShoppingCart className='quickButtonIcon' /> Ekle
                        </a>
                        <a href='#' className='productQuickButtonMobile'>
                            <AiOutlineWhatsApp className='quickButtonIcon' /> Bilgi Al
                        </a>
                    </div>
                </>
            )
        } else {
            return <BlankProductComponent />
        }
    }

    return (
        <a onClick={_onClick} className={isMobile ? id ? 'productContainerMobile' : 'productContainerMobileBlank' : id ? 'productContainerMid' : 'productContainerMidBlank'} style={containerStyle}>
            {_renderContent()}
        </a>
    )
}
