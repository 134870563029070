import { collection, getDocs, limit, query, startAfter } from "firebase/firestore";
import { db } from "../../../firebase";

export const getProducts = async (args) => {
    try {
        const productsRef = collection(db, 'products');
        const productQuery = !args?.lastDocument ? query(productsRef, limit(20)) : query(productsRef, limit(20), startAfter(args?.lastDocument));
        const querySnapshot = await getDocs(productQuery);

        const productList = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return data;
        });

        const lastData = querySnapshot.docs[querySnapshot.docs.length - 1];
        return {
            list: productList,
            lastDocument: lastData,
        };
    }
    catch (error) {
        return null;
    }
};
