import React from 'react'
import './ContactScreenStyles.css'
import { GoLocation } from 'react-icons/go'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'

export default function ContactScreen() {
  return (
    <div className='contactScreenContainer'>
      <div className='contactMapContainer'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6022.378291602363!2d28.840369284012514!3d40.9992330879338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa56a32d33943%3A0x11b7fab86ccbe9e4!2zRHVydSDDp2nDp2Vrw6dpbGlr!5e0!3m2!1str!2str!4v1662419197411!5m2!1str!2str" width="90%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className='contactScreenInfo'>
        <div>
          <GoLocation style={{ color: '#99ce76', marginRight: 5 }} /> Şirinevler Mahallesi. Saray Caddesi. No:42a - Bahçelievler/İstanbul, 34188
        </div>
        <div>
          <AiOutlinePhone style={{ color: '#99ce76', marginRight: 5 }} /> 0(530) 783 44 78
        </div>
        <div>
          <AiOutlineMail style={{ color: '#99ce76', marginRight: 5 }} /> mustafagundogdu4434@gmail.com
        </div>
      </div>
    </div>
  )
}
