const ScreenSize = {
    BIG_SCREEN: 1110,
    MIDDLE_SCREEN: 850
}

export const ScreenSizeEnum = {
    BIG: 3,
    MIDDLE: 2,
    MOBILE: 1
}

export default ScreenSize